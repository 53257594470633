import * as React from "react"
import Layout from "../components/Layout"
import herobg from '../images/backgrounds/platform-hero-bg.webp'
import hero from '../images/platform-hero.webp'
import whitebg from '../images/backgrounds/whitebg.jpg'
import opt4 from '../images/opt-4.webp'
import envelope4 from '../images/envelope-4.webp'
import marsclose1 from '../images/mars-close-1.webp'
import marsclose2 from '../images/mars-close-2.webp'
import universe1 from '../images/universe-1.webp'

export default function PlatformPage() {
    return (
        <Layout>

                <section className="h-screen w-screen flex flex-col justify-center items-center bg-cover" style={{backgroundImage: `url(${herobg})`}}>
                    <div className="h-full w-full px-28 flex flex-row">

                        <div className="h-fit w-2/5 my-auto text-black">
                            <h1 className="text-8xl drop-shadow-md font-geometry uppercase">Optimus,</h1>
                            <h2 className="text-5xl drop-shadow-md font-geometry mt-28">Your Next Generation<br/>Space Explorer</h2>
                            <text className="text-5xl drop-shadow-md font-geometry text-left tracking-widest">...</text>
                        </div>

                        <div className="h-fit w-2/3 my-auto p-20">
                            <img src={hero} />
                        </div>

                    </div>
                </section>


                <section className="h-fit w-screen flex flex-col bg-repeat text-black py-12 px-28" style={{backgroundImage: `url(${whitebg})`}}>

                    <div>
                        <h1 className="text-5xl font-semibold uppercase">The Power And Propulsion Provider</h1>
                        <p className="text-xl text-black font-light tracking-wide mt-5">Space Machines' reusable architecture supports a wide range of mission requirements. Optimus is powered by a scalable and cost-effective spacecraft, modular interfaces, and innovative in-space propulsion configurations.</p>
                    </div>

                    <div className="w-full">
                        <img src={opt4} />
                    </div>

                    <div className="w-4/5 mx-auto flex flex-row gap-x-20">
                        <p className="font-light text-xl uppercase">A range of reusable orbital transport vehicles to suit your science missions</p>
                        <p className="font-light text-xl uppercase">Versatility to support low earth orbit to deep space and interplanetary missions</p>
                        <p className="font-light text-xl uppercase">Flexible and cost-effective with rideshare and dedicated flight options for customers</p>
                    </div>

                </section>


                <section className="h-fit w-screen flex flex-col bg-olive text-black py-12 px-28">

                    <h1 className="text-5xl font-semibold uppercase">Configurable To Your Needs</h1>

                    <div className="mt-10">
                        <img src={envelope4} />
                    </div>

                </section>


                <section className="h-fit w-screen flex flex-row justify-between gap-x-28 text-black py-12 px-28">
                    <div>
                        <img className="rounded-lg" src={marsclose1} />
                    </div>
                    <div>
                        <img className="rounded-lg" src={marsclose2} />
                    </div>
                </section>


                <section className="h-fit w-screen flex flex-row items-center px-28 py-10 bg-berry">

                    <div className="w-1/2 flex flex-col">
                        <h1 className="text-7xl font-geometry uppercase">Science</h1>
                        <p className="text-xl drop-shadow-md font-light tracking-wide mt-5">
                            We're laying the groundwork for a new approach to space science, exploration, and operations by demonstrating new technologies, performing extensive background research and designing science-driven mission profiles.
                            This will culminate in the open-access Spectrum Repository, allowing the next generation of space scientists and explorers to plan their next mission to space, and clearly showing that cost and convenience are no longer a barrier to operating small missions.
                        </p>
                        <h2 className="text-5xl drop-shadow-md font-geometry text-white text-left tracking-widest">...</h2>
                    </div>

                    <div className="w-1/2">
                        <img src={universe1} />
                    </div>

                </section>

        </Layout>
    )
}
